/* utils */
import { getPublicBundleList } from "@utils/fetch";
import { serializeProductData } from "@utils/detail";
import { getProduct, addToBasket } from "@utils/fetch";
import { addToCart, productDetailView, onSelectSize } from "@utils/analytics";
import { getDiscountPrice } from "../../utils/helpers";

const actions = {
  GET_BUNDLE: "GET_BUNDLE",
  SET_STEP: "SET_STEP",
  SET_PDP_LOADING: "SET_PDP_LOADING",
  SET_PDP_CART_LOADING: "SET_PDP_CART_LOADING",
  STOP_PDP_CART_LOADING: "STOP_PDP_CART_LOADING",
  SET_PRODUCT_VARIANT: "SET_PRODUCT_VARIANT",
  SET_CHOICE_VARIANT: "SET_CHOICE_VARIANT",
  SET_CHOICE_SIZE: "SET_CHOICE_SIZE",
  SET_TO_BUY: "SET_TO_BUY",
  SET_CHOICE_INSEAM: "SET_CHOICE_INSEAM",
  SET_STEP_SIZE_ALERT: "SET_STEP_SIZE_ALERT",
  ADD_TO_CART: "ADD_TO_CART",
  TOGGLE_SIZES_POPUP: "TOGGLE_SIZES_POPUP",
  SET_STEP_BAR_MINIMIZED: "SET_STEP_BAR_MINIMIZED",
  TOGGLE_SLIDER_GALLERY: "TOGGLE_SLIDER_GALLERY",
  SET_MOUSEOVER_SIZE: "SET_MOUSEOVER_SIZE",
  REMOVE_MOUSEOVER_SIZE: "REMOVE_MOUSEOVER_SIZE",
  RESET_ADD_TO_CART_COMPLETE: "RESET_ADD_TO_CART_COMPLETE"
};

export default actions;

export function setStep(context, stepIndex = 0) {
  const { state, dispatch } = context;

  if (state.bundle) {
    if (state.step?.index === stepIndex) {
      return;
    }

    if (!state.loading) {
      setLoading(context);
    }

    const bundleProducts = state.bundle.products;
    if (bundleProducts.length > stepIndex) {
      // get current step product data through websphere
      const sku = bundleProducts[stepIndex].sku;
      getProduct(bundleProducts[stepIndex].prepend + sku).then(apiResponse => {
        const colors = apiResponse.attributes.find(attr => attr.type === 'color').options.map((val) => ({
          code: val.value,
          label: val.label,
          swatchColors: val.swatch.color
        }))
        const choiceVariantFromColors = colors.find(
            ({ code }) =>
                code ===
                state.bundleConfiguration[
                    state.bundle.products[stepIndex].sku
                    ]?.variant
        );
        const variantFromColors = colors.find(
            ({ code }) =>
                code === state.bundle.products[stepIndex].variant
        );
        let fallbackVariantFromColors;
        if (state.bundle.data?.fallback_variants_availability?.[sku]) {
          fallbackVariantFromColors = colors.find(
              ({ code }) =>
                  code ===
                  state.bundle.data.fallback_variants_availability[sku]
          );
        } else {
          fallbackVariantFromColors = colors.find(
              ({ code }) =>
                  code === state.bundle.data.fallback_variants[sku]
          );
        }

        const variant =
            choiceVariantFromColors?.code ||
            variantFromColors?.code ||
            fallbackVariantFromColors?.code ||
            colors[0]?.code;

        const serializedProduct = serializeProductData(apiResponse, variant)
        productDetailView({
          id: serializedProduct.id,
          masterId: serializedProduct.masterId,
          avgRating: serializedProduct.xcatentry_ProductRating,
          bundleId: state.bundle.id,
          colorCode: serializedProduct.variant.code,
          colorDescription: serializedProduct.variant.label,
          name: serializedProduct.name,
          numReviews: serializedProduct.xcatentry_ProductRatingCount,
          price: getDiscountPrice(serializedProduct.finalPrice, state.bundle.discount),
          finalPrice: serializedProduct.finalPrice,
          saleDiscountAmount: state.bundle.discount,
          variant: serializedProduct.variant.code,
          sku,
          partNumber: apiResponse.variants[0].id
              .split(':').slice(0,2).join(':')
        }, state.bundle, stepIndex)


        dispatch({
          type: actions.SET_STEP,
          step: {
            index: stepIndex,
            product: serializedProduct
          },
        });
      })
    }
  }
}

export function setLoading(context) {
  const { dispatch } = context;
  dispatch({
    type: actions.SET_PDP_LOADING,
  });
}

export function getBundle(context, id, preview) {
  const { dispatch } = context;

  getPublicBundleList({ id, published: preview }).then((data) => {
    if (data.status === 200 && data.bundles.length > 0) {
      dispatch({
        type: actions.GET_BUNDLE,
        bundle: {
          ...data.bundles[0],
          products: JSON.parse(data.bundles[0].products),
          data: JSON.parse(data.bundles[0].data),
        },
      });
    }
  });
}

export function setVariant({ state, dispatch }, sku, variant, prepend) {
  setLoading({ dispatch });
  getProduct(prepend + sku).then(apiResponse => {
    const serializedData = serializeProductData(apiResponse, variant.code)
    let size;
    // If there's inseam select size with inseam otherwise consider only size
    if (state.bundleConfiguration[sku]?.inseam) {
      size = serializedData.sizes.find(
          (item) =>
              item.size === state.bundleConfiguration[sku]?.size &&
              item.inseamKey === state.bundleConfiguration[sku]?.inseam
      );
    } else {
      size = serializedData.sizes.find(
          (item) => item.size === state.bundleConfiguration[sku]?.size
      );
    }
    const inseam = serializedData.hasInseam
        ? serializedData.inseam.find(
            (item) =>
                item.inseamKey === state.bundleConfiguration[sku]?.inseam
        )
        : null;

    // Autoselect One Size
    if (!size && serializedData.sizes.length === 1) {
      size = serializedData.sizes[0];
    }

    dispatch({
      type: actions.SET_CHOICE_VARIANT,
      product: sku,
      variant: variant.code,
      sizeData: size,
      inseamData: inseam,
      hasInseam: serializedData.hasInseam,
      stepProduct: serializedData,
    });
  });
}

export function setSize({ state, dispatch }, size, catId, display, stockLeft) {
  onSelectSize(size, state.bundle.name, state.step.product)
  dispatch({
    type: actions.SET_CHOICE_SIZE,
    product: state.step.product.partNumber,
    size: size,
    stockLeft: stockLeft,
    catId: catId,
    sizeLabel: display,
  });
}

export function setToBuy({ dispatch, state }, toBuy) {
  dispatch({
    type: actions.SET_TO_BUY,
    product: state.step.product.partNumber,
    toBuy
  });
}

export function setSizeAlert({ dispatch }, active) {
  dispatch({
    type: actions.SET_STEP_SIZE_ALERT,
    active,
  });
}

export async function addBundleToCart({ state, dispatch }) {
  dispatch({ type: actions.ADD_TO_CART });
  dispatch({ type: actions.SET_PDP_CART_LOADING });
  let resultData = await addToBasket(state.bundleConfiguration, state.isBundlePage);


  if (resultData) {
    addToCart(Object.keys(state.bundleConfiguration).map(key=>{
      return {
        sku: key,
        size: state.bundleConfiguration[key].size,
        name: state.bundleConfiguration[key].name,
        price: state.bundleConfiguration[key].price,
        finalPrice: state.bundleConfiguration[key].finalPrice,
        colorDescription: state.bundleConfiguration[key].colorDescription,
        colorCode: state.bundleConfiguration[key].colorCode,
        bundleId: state.bundle.id,
        id: state.bundleConfiguration[key]?.catId,
      }
    }), state.bundle, state.step.index);

    setTimeout(() => {
      dispatch({ type: actions.STOP_PDP_CART_LOADING });
      setTimeout(() => {
        dispatch({ type: actions.RESET_ADD_TO_CART_COMPLETE });
      }, 7000);
    }, 500);
  } else {
    dispatch({ type: actions.STOP_PDP_CART_LOADING });
  }
}

export function toggleSizesPopup({ dispatch }) {
  dispatch({ type: actions.TOGGLE_SIZES_POPUP });
}

export function setInseam({ state, dispatch }, inseam) {
  dispatch({
    type: actions.SET_CHOICE_INSEAM,
    product: state.step.product.partNumber,
    inseam: inseam,
  });
}

export function setStepBarMinimized({ dispatch }, state) {
  dispatch({
    type: actions.SET_STEP_BAR_MINIMIZED,
    minimized: state,
  });
}

export function toggleSliderGallery({ dispatch }) {
  dispatch({
    type: actions.TOGGLE_SLIDER_GALLERY,
  });
}

export function setMouseoverSize({dispatch}, size){
  dispatch({
    type: actions.SET_MOUSEOVER_SIZE,
    size
  });
}

export function removeMouseoverSize({dispatch}){
  dispatch({
    type: actions.REMOVE_MOUSEOVER_SIZE,
  });
}