import { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'

/* components */
import BundleItem from "@components/plp/BundleItem"
import BundleGroupItem from "@components/plp/BundleGroupItem"
import Skeleton from "@components/plp/Skeleton"

/* styles */
import style from "@styles/plp/BundleList.module.scss"

/* context */
import context from "@context/plp"

export default function CollectionList({ preview, bundles, prices }) {
  const { state } = useContext(context)
  const { t } = useTranslation()
  const [visibleCount, setVisibleCount] = useState(6)
  const [loadingMore, setLoadingMore] = useState(false)

  const applyFilters = (bundle) => {
    const { active: activeFilters } = state.filters
  
    // Check gender filter
    if (activeFilters.gender) {
      const genderToCheck = [...activeFilters.gender];
      if (genderToCheck.includes('men') || genderToCheck.includes('women')) {
        genderToCheck.push('unisex');
      }
      
      const gender = bundle.gender
      if (!gender || !genderToCheck.includes(gender)) {
        return false
      }
    }
  
    return true
  }

  const createRenderList = () => {
    const { active: activeFilters } = state.filters
  
    return bundles.map(bundleOrGroup => {
      if (activeFilters.collection) {
        const associatedLowerCase = bundleOrGroup.associated ? bundleOrGroup.associated.toLowerCase() : ''
        const activeCollectionsLowerCase = activeFilters.collection
          .filter(c => typeof c === 'string')
          .map(c => c.toLowerCase())
  
        if (!associatedLowerCase || !activeCollectionsLowerCase.includes(associatedLowerCase)) {
          return null
        }
      }
  
      if (bundleOrGroup.gender) {
        return applyFilters(bundleOrGroup) ? bundleOrGroup : null
      } else {
        const filteredBundles = bundleOrGroup.bundles.filter(bundle => applyFilters(bundle))
        return filteredBundles.length > 0 ? { ...bundleOrGroup, bundles: filteredBundles } : null
      }
    }).filter(Boolean)
  }

  let renderList = createRenderList()

  useEffect(() => {}, [renderList])

  const hasThreeItemsBundles = renderList.some(b => b.products && b.products.length > 2)
  const visibleItems = renderList.slice(0, visibleCount)

  const handleShowMore = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth"
      })
    }, 50)

    setLoadingMore(true)
    
    setTimeout(() => {
      setVisibleCount(visibleCount + 6)
      setLoadingMore(false)
    }, 1500)
  }

  return (
    <div className={`${style.container} ${state.filters.active ? style.activeFilters : ''}`}>
      {state.loading ? (
        <Skeleton visible={true} />
      ) : (
        <>
          {visibleItems.map((item, index) => {
            if (item.gender) {
              return (
                <BundleItem key={index} bundle={item} preview={preview} threeItemsLayout={hasThreeItemsBundles} />
              )
            } else {
              return (
                <BundleGroupItem key={index} bundleGroup={item} preview={preview} prices={prices} context="collection" />
              )
            }
          })}
          {visibleCount < renderList.length && (
            <div className={style.showMoreContainer}>
              {loadingMore ? (
                <Skeleton visible={loadingMore} />
              ) : (
                <Link onClick={handleShowMore} className={style.showMore}>{t('Show More')}</Link>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}