import './styles/index.scss';
import { HashRouter, Routes, Route } from "react-router-dom"
import { useEffect, useState } from 'react'

/* pages */
import Listing from "@pages/Listing"
import Collection from "@pages/Collection"
import Detail from "@pages/Detail"

function App({ pageType }) {
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    setPreview(window.location.href.indexOf("?preview=") > 0 ? true : false)
  }, [])

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={
            pageType === 'collection-group'
              ? <Collection key={`collection-${pageType}`} preview={preview} />
              : pageType === 'bundle-group'
              ? <Listing key={`listing-${pageType}`} preview={preview} />
              : null
          } />
          <Route path="/bundle-detail/:id" element={<Detail preview={preview}/>} />
      </Routes>
    </HashRouter>
  );
}

export default App