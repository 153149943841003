import { getDiscountPrice, getCurrencyCode, getCountryCode } from "./helpers";

const sendEvent = (event) => {
  if (isDataLayerAvailable()) {
    window.dataLayer.push(event);
  } else {
    console.log(event);
  }
};

const getCurrency = () => {
  if (isDataLayerAvailable()) {
    return window.dataLayer[0].currency;
  } else {
    return null;
  }
}

const getCurrencySymbol = () => {
  if (isDataLayerAvailable()) {
    return window.dataLayer[0].currencySymbol;
  } else {
    return null;
  }
}

const isDataLayerAvailable = () => {
  return window.dataLayer && window.dataLayer.push;
};

const isBundlePage = () => {
  const pageType = document.getElementById('root').getAttribute('data-page-type') || 'bundle-group'
  return pageType === 'bundle-group'
}

const getEventCategory = () => {
  return isBundlePage ? 'Product Bundles' : 'Collection Experience'
}

const mapProductData = (product, bundle) => {
  const originalPrice = parseFloat(parseFloat(product.finalPrice).toFixed(2))
  return {
    list: getEventCategory(),
    category: getEventCategory(),
    avgRating: product?.avgRating,
    badge: undefined, // ???????
    brand: 'THENORTHFACE-' + getCountryCode(),
    bundleId: bundle.id,
    catalogCategory: undefined,
    colorCode: product.colorCode,
    colorDescription: product.colorDescription,
    discountType: undefined,
    id: product.id,
    masterId: product.masterId,
    merchProductId: product.id,
    name: product.name,
    numReviews: product.numReviews,
    onSale: false,
    saleDiscountAmount: 0,
    onCoupon: isBundlePage(),
    couponDiscountAmount: parseFloat(parseFloat(originalPrice - product.price).toFixed(2)),
    originalPrice,
    position: undefined,
    price: product.price,
    searchTerm: undefined,
    styleCode: product.sku,
    taxTotal: undefined,
    variant: product.variant
  }
}

const getProductsFromBundles = (bundles) => {
  return bundles.reduce((acc, bundle, bundleIndex) => {
    let data = {};
    let products = [];
    try {
      data = JSON.parse(bundle.data || '{}');
      products = JSON.parse(bundle.products || '[]');
    } catch (e) {
      console.error('Error parsing JSON for bundle:', bundle, e);
      return acc;
    }
    const impressionProducts = products.map((product, productIndex) => ({
      list: getEventCategory(),
      onSale: false,
      saleDiscountAmount: 0,
      onCoupon: isBundlePage(),
      category: getEventCategory(),
      bundleId: bundle.id,
      colorCode: product.variant || data?.fallback_variants?.[product.sku],
      id: `${product.prepend}${product.sku}`,
      masterId: `${product.prepend}${product.sku}`,
      merchProductId: `${product.prepend}${product.sku}${product.variant || ''}`,
      position: (2 * bundleIndex) + (productIndex + 1),
      brand: 'THENORTHFACE-' + getCountryCode(),
      avgRating: undefined,
      badge: undefined,
      catalogCategory: undefined,
      colorDescription: undefined,
      discountType: undefined,
      name: undefined,
      numReviews: undefined,
      originalPrice: undefined,
      price: undefined,
      searchTerm: undefined,
      styleCode: undefined,
      taxTotal: undefined,
      variant: undefined,
    }));
    return [...acc, ...impressionProducts];
  }, [])
}

export const onExperienceStart = () => {
  const event = {
    event: 'loadEventData',
    eventCategory: getEventCategory(),
    eventAction: "Experience Start",
    eventLabel: 'Step 1 - Load Bundles',
    nonInteractive: true,
    customMetrics: {},
    customVariables: {},
    _clear: true
  }

  return sendEvent(event)
}

export const onSelectSize = (size, bundleId, product) => {
  const event = {
    event: 'loadEventData',
    eventCategory: getEventCategory(),
    eventAction: 'Select Size',
    eventLabel: `${bundleId} - ${product.id} - ${size}`,
    nonInteractive: true,
    customMetrics: {},
    customVariables: {
      bundleId
    },
    _clear: true
  }

  return sendEvent(event)
}

export const onGoToBundle = (bundleId, originalPrice, discountPercentage) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': getEventCategory(),
    'eventAction': 'Step Completed',
    'eventLabel': 'Step 1 - Load Bundles',
    'nonInteractive': false,
    customMetrics: {},
    customVariables: {
      bundleId,
    },
    _clear: true
  }
  return sendEvent(event)
}

export const onGoToNextProduct = (bundleId, originalPrice, discountPercentage, step) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': getEventCategory(),
    'eventAction': 'Step Completed',
    'eventLabel': 'Step 2 - Shop Bundle',
    'nonInteractive': false,
    'customMetrics': {},
    'customVariables': {
      bundleId,
    },
    _clear: true
  }
  return sendEvent(event)
}

const nextProductCompleted = (bundle) => {
  const event = {
    'event': 'loadEventData',
    'eventCategory': getEventCategory(),
    'eventAction': 'Step Completed',
    'eventLabel': 'Step 3 - Next Product',
    'nonInteractive': false,
    'customMetrics': {},
    'customVariables': {
      bundleId: bundle.id,
    },
    _clear: true
  }
  return sendEvent(event)
}

export const addToCart = (products, bundle, stepIndex) => {
  nextProductCompleted(bundle)
  const event = {
    event: "addToCart",
    ecommerce: {
      currencyCode: getCurrencyCode(),
      add: {
        products: products.map((product) => {
          const p = mapProductData(product, bundle)
          return {
            ...p,
            quantity: 1,
            size: product.size,
            colorCode: product.colorCode,
            variant: product.colorCode,
            colorDescription: product.colorDescription,
            name: product.name,
            price: product.price,
            id: product.id.replaceAll(':', ''),
            masterId: product.id.split(':')[0] + product.id.split(':')[1],
            merchProductId: product.id.split(':')[0] + product.id.split(':')[1] + product.id.split(':')[2],
          }
        })
      },
    },
    customMetrics: {},
    customVariables: {
      bundleId: bundle.id
    },
    _clear: true,
  };
  return sendEvent(event);
};

export const productDetailView = (product, bundle, stepIndex) => {
  const event = {
    event: 'productDetailView',
    eventCategory: getEventCategory(),
    eventAction: 'Product Detail View',
    eventLabel: `${product.partNumber.replace(':', '')}${product.colorCode}`,
    nonInteractive: true,
    ecommerce: {
      currencyCode: getCurrencyCode(),
      detail: {
        actionField: {},
        products: [mapProductData(product, bundle)]
      }
    },
    customMetrics: {
      bundleValue: getDiscountPrice(bundle.price, bundle.discount),
      bundleOriginalValue: bundle.price,
    },
    customVariables: {
      bundleId: bundle.id
    },
    _clear: true
  }
  return sendEvent(event)
}

export const productImpressions = (bundles) => {
  const products = getProductsFromBundles(bundles)
  if (!products.length) {
    return
  }
  const chunkSize = 24;
  for (let i = 0; i < products.length; i += chunkSize) {
    const chunk = products.slice(i, i + chunkSize);
    const event = {
      'event': 'productImpressions',
      'eventCategory': getEventCategory(),
      'eventAction': 'Product Impressions',
      'nonInteractive': true,
      ecommerce: {
        currencyCode: getCurrencyCode(),
        impressions: {
          products: chunk
        },
      },
      customVariables: {},
      _clear: true
    };
    sendEvent(event);
  }
};

export const productClick = (product) => {
  onGoToBundle(product.bundleId)
  const event = {
    event: 'productClick',
    eventCategory: getEventCategory(),
    eventAction: 'Product Clicks',
    eventLabel: `${product.prepend}${product.sku}`, // PrO
    ecommerce: {
      currencyCode: getCurrencyCode(),
      click: {
        products: [{
          list: getEventCategory(),
          brand: 'THENORTHFACE-' + getCountryCode(),
          onSale: false,
          saleDiscountAmount: 0,
          discountType: undefined,
          onCoupon: isBundlePage(),
          category: getEventCategory(),
          bundleId: product.bundleId,
          colorCode: product.variant || undefined,
          id: `${product.prepend}${product.sku}`,
          masterId: `${product.prepend}${product.sku}`,
          merchProductId: `${product.prepend}${product.sku}${product.variant || ''}`,
          avgRating: undefined,
          badge: undefined,
          catalogCategory: undefined,
          colorDescription: undefined,
          name: undefined,
          numReviews: undefined,
          originalPrice: undefined,
          price: undefined,
          searchTerm: undefined,
          styleCode: undefined,
          taxTotal: undefined,
          variant: undefined,
        }]
      }
    },
    customVariables: {
      bundleId: product.bundleId
    }
  }

  return sendEvent(event);
}

// NOT USED EVENTS

export const onApplyFilter = (filterName) => {
  return
  const event = {
    'event': 'loadEventData',
    'eventCategory': 'PDP',
    'eventAction': 'Product Bundle_Apply Filter',
    'eventLabel': filterName,
    'nonInteractive': false,
    'customMetrics': {},
    'customVariables': {
      'functionality': 'Product Bundle',
    },
    _clear: true
  }
  return sendEvent(event)
}

export const colorVariantChange = (sku, colorDesc) => {
  return
  const event = {
    'event': 'loadEventData',
    'eventCategory': 'PDP',
    'eventAction': 'Color Click',
    'eventLabel': `${sku} - ${colorDesc}`,  // eg."NF0A4R2A: Men’s Apex Bionic Jacket",
    'nonInteractive': false,
    'customMetrics': {},
    _clear: true,
    customVariables: {
      functionality: 'Product Bundle',
    },
  }
  return sendEvent(event)
}

