import { useNavigate } from 'react-router-dom'
import { toggleFilter, setFilterLoading } from '@context/plp/actions'
import style from '@styles/plp/Filters.module.scss'
import { updateURLWithFilters } from '@utils/listing'

export default function ActiveFilter({ property, value, state, dispatch, t }) {
  const navigate = useNavigate()
  const lowerCaseValue = typeof value === 'string' ? value.toLowerCase() : value

  const handleRemove = () => {
    toggleFilter({ state, dispatch }, { property, value }, t)

    const updatedFilters = {
      ...state.filters.active,
      [property]: state.filters.active[property].filter(v => v !== value)
    }

    if (updatedFilters[property].length === 0) {
      delete updatedFilters[property]
    }

    setFilterLoading({ dispatch }, false)
    updateURLWithFilters(updatedFilters, navigate)
  }

  const translatedValue = t(`plp.FILTERS.${value.toUpperCase()}`, value)

  // Condition to show unisex only if it is selected alone
  const shouldDisplayUnisex = property !== 'gender' || lowerCaseValue !== 'unisex' || 
    (state.filters.active.gender.length === 1 && state.filters.active.gender[0].toLowerCase() === 'unisex')

  return (
    <>
      {shouldDisplayUnisex && (
        <div className={style.activeFilter}>
          <span>{translatedValue}</span>
          <button onClick={handleRemove} className={style.removeFilter}>
            &times;
          </button>
        </div>
      )}
    </>
  )
}