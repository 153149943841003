import { useState, useContext } from 'react';
import { ReactComponent as ArrowDown } from '@svg/arrowDown.svg';

/* context */
import context from "@context/pdp"

/* styles */
import style from "@styles/pdp/CollapsibleInfo.module.scss"

/* utils */
import { decodeEntities } from "@utils/helpers"
import { useTranslation } from "react-i18next";
import "../../i18n";

export default function CollapsibleInfo() {
    const { t } = useTranslation("translation");

    const [collapsed, setCollapsed] = useState(null);
    const { state } = useContext(context);

    const handleClick = (info) => {
        if (collapsed === info) {
            setCollapsed(null);
        }
        else {
            setCollapsed(info);
        }
    }

    const getClassName = (info) => {
        if (collapsed === info) return `${style.item} ${style.itemCollapsed}`
        return style.item
    }

    console.log(state.step.product)

    return (
        <div className={style.container}>
            {/* details */}
            {state.step.product.longDescription ? <div className={getClassName('details')}>
                <div className={style.header} onClick={() => handleClick('details')}>
                    <span className={style.label}>{t('pdp.DETAILS')}</span>
                    <div className={style.arrow}>
                        <ArrowDown />
                    </div>
                </div>
                <div className={style.info}>
                    <p dangerouslySetInnerHTML={{ __html: decodeEntities(state.step.product.longDescription) }}></p>
                </div>
            </div> : null}

            {/* benefits */}
            {state.step.product.benefits?.length ? <div className={getClassName('benefits')}>
                <div className={style.header} onClick={() => handleClick('benefits')}>
                    <span className={style.label}>{t('pdp.BENEFITS')}</span>
                    <div className={style.arrow}>
                        <ArrowDown />
                    </div>
                </div>
                <div className={style.info}>
                    {state.step.product.benefits.map((benefit, index) => (
                        <div className={style.benefitContainer} key={index}>
                            <img src={benefit.thumbnail} alt={benefit.label} />
                            <div>
                                <h3>{benefit.label}</h3>
                                <p>{benefit.text}</p>
                            </div>
                        </div>)
                    )}
                </div>
            </div> : null}

            {/* intended use */}
            {state.step.product.intendedUse?.length ? <div className={getClassName('intended_use')}>
                <div className={style.header} onClick={() => handleClick('intended_use')}>
                    <span className={style.label}>{t('pdp.INTENDED-USE')}</span>
                    <div className={style.arrow}>
                        <ArrowDown />
                    </div>
                </div>
                <div className={style.info}>
                    <div className={style.useWrapper}>
                        {state.step.product.intendedUse.map((use, index) => (
                            <div className={style.useContainer} key={index}>
                                <img src={use.icon} alt={use.label} />
                                <div>
                                    <h3>{use.label}</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div> : null}

            {/* features */}
            {state.step.product.features?.length ? <div className={getClassName('features')}>
                <div className={style.header} onClick={() => handleClick('features')}>
                    <span className={style.label}>{t('pdp.FEATURES')}</span>
                    <div className={style.arrow}>
                        <ArrowDown />
                    </div>
                </div>
                <div className={style.info}>
                        <ul className={style.featureWrapper}>
                            {state.step.product.features.map((feature, index) => (
                                <li className={style.featureContainer} key={index}>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                </div>
            </div> : null}

            {/* technology */}
            {state.step.product.technology?.length ? <div className={getClassName('technology')}>
                <div className={style.header} onClick={() => handleClick('technology')}>
                    <span className={style.label}>{t('pdp.TECHNOLOGY')}</span>
                    <div className={style.arrow}>
                        <ArrowDown />
                    </div>
                </div>

                <div className={style.info}>
                    {state.step.product.technology.map((tech, index) => (
                        <div key={index} className={style.techContainer}>
                            <h3>{tech.label}</h3>
                            <p dangerouslySetInnerHTML={{ __html: tech.text }} />
                        </div>)
                    )}
                </div>
            </div> : null}

            {/* how to care */}
            {state.step.product.howToCare?.length ? <div className={getClassName('how_to_care')}>
                <div className={style.header} onClick={() => handleClick('how_to_care')}>
                    <span className={style.label}>{t('pdp.HOW-TO-CARE')}</span>
                    <div className={style.arrow}>
                        <ArrowDown />
                    </div>
                </div>
                <div className={style.info}>
                    {state.step.product.howToCare.map((benefit, index) => (
                        <div className={style.benefitContainer} key={index}>
                            <img src={benefit.icon} alt={benefit.label} />
                            <div>
                                <h3>{benefit.label}</h3>
                                <p>{benefit.description}</p>
                            </div>
                        </div>)
                    )}
                </div>
            </div> : null}

        </div>
    );
}